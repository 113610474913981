// src/controllers/content_loader_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "output_price",
    "output_price_html",
    "output_status",
    "output_branch",
    "output_room",
  ];

  connect() {
    console.log("connected to Stimulus hello hello")
  }

  click() {
    this.outputTarget.innerHTML = "htmlaa";
    console.log("click")
  }

  change() {
    console.log("change")
  }

  input() {
    console.log("input")
  }


  count(e) {
    console.log("count count")

    }

  selectHandler(e) {
    let id = e.target.value;
    //let id = 2;
    fetch(`/units/${id}.json`)
      .then((response) => response.json())
      .then((data) => {
        this.output_priceTarget.value = data.price;
        this.output_price_htmlTarget.innerHTML = data.price;
        this.output_roomTarget.innerHTML = data.room_no;
        this.output_statusTarget.innerHTML = data.status;
        this.output_branchTarget.innerHTML = data.branch.name;
      });
  }

  discountHandler() {
    let discount = this.output_priceTarget.value;
    console.log(discount);
    console.log("discount");

    var discounted = Math.round(discount * 0.9).toFixed(0);
    console.log("discounted");
    this.output_priceTarget.value = discounted;
  }
}
