// src/controllers/content_loader_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output", "input"];

  connect() {
    // this.outputTarget.classList.add("hidden");
  }

  click() {
    fetch(this.data.get("url"))
      .then((response) => response.text())
      .then((html) => {
        this.outputTarget.innerHTML = html;
        // this.outputTarget.classList.remove("hidden");
      });
  }

  close() {
    console.log("Yellow in zbras");
    this.outputTarget.classList.add("hidden");
  }

  greet() {
    fetch(this.data.get("url"))
      .then((response) => response.text())
      .then((html) => {
        this.inputTarget.innerHTML = html;
      });
  }

  disconnect() {}
}
