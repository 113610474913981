import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['output', 'name']

	greet() {
	// var element = this.nameTargets
	// for(var index in this.nameTargets)
	//	this.nameTargets[index].innerHTML = index
	// var roomster = this.roomId
	
	this.roomId = this.data.get("roomId")
		console.log(this.roomId)
	this.outputTarget.innerHTML = 'lll'
	}
}
