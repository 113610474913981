// src/controllers/content_loader_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["heading"]
  connect() {
    console.log("clickety click")
    // this.click()
  }

  click() {
    fetch(this.data.get("url"))
      .then(response => response.text())
      .then(html => {
        this.headingTarget.innerHTML = html        
      })
  }
}