console.log("autocomplete.js");
// import 'js-autocomplete/auto-complete.css';
// import autoComplete from 'js-autocomplete';

const autocompleteSearch = function () {
  console.log("autocomplete.js inside autocompleteSearch function");
  const skills = JSON.parse(
    document.getElementById("search-data").dataset.skills
  );
  const searchInput = document.getElementById("q");
  if (skills && searchInput) {
    new autoComplete({
      selector: searchInput,
      minChars: 1,
      source: function (term, suggest) {
        term = term.toLowerCase();
        const choices = skills;
        const matches = [];
        for (let i = 0; i < choices.length; i++)
          if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
        suggest(matches);
      },
    });
  }
};
console.log("denne her");
export { autocompleteSearch };
